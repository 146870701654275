export const DOMAIN = process.env.DOMAIN || 'predprof.edu-demo.com';
export const CLIENT_ID = process.env.CLIENT_ID || DOMAIN;
export const MCKO_AUTH_SERVER = process.env.MCKO_AUTH_SERVER || 'https://login.predprof.edu-demo.com/';
export const LOGIN_URL = process.env.LOGIN_URL || 'https://login.predprof.edu-demo.com/';

const auth = 'auth';
export const eventApi = 'event';
const attach = 'attach';
const results = 'results';
export const metaEventApi = 'meta_event';

export const trendsApi = `/${eventApi}/trends`;
export const eventsApi = `/${eventApi}/events`;
export const eventUsersApi = `/${eventApi}/event_users`;
export const usersApi = `/${eventApi}/users`;
export const directionsApi = `/${eventApi}/directions`;
export const departmentsApi = `/${auth}/departments`;
export const attachmentsApi = `/${attach}/attachments`;
export const eventGroupsApi = `/${eventApi}/groups`;
export const groupUsersApi = `/${eventApi}/group_users`;
export const tagsApi = `/${eventApi}/tags`;
export const timeslotsApi = `/${eventApi}/timeslots`;
export const landingApi = `/${eventApi}/landing_blocks`;
export const locationsApi = `/${eventApi}/locations`;
export const userApi = `/${auth}/user`;
export const resultsApi = `/${results}`;
export const certificatesApi = `/${results}/certificates`;
export const metaEventsApi = `/${metaEventApi}/metaEvents`;
export const academicYearApi = `/${eventApi}/dictionaries/academic_years`;
export const teamsApi = `/${eventApi}/teams`;
export const filtersApi = `${auth}/filters`;
export const globalEventsApi = `/${eventApi}/combine_events`;
