import { lazy } from 'react';
import { RoutesComponentsType } from 'constants/routes';


export const LAZY_COMPONENTS: RoutesComponentsType = {
  Main: lazy(() => import('components/pages/Main')),
  Registration: lazy(() => import('components/pages/Registration')),
  RegistrationOfOperator: lazy(() => import('components/pages/RegistrationOfOperator')),
  WithoutEmail: lazy(() => import('components/pages/WithoutEmail')),
  Events: lazy(() => import('components/pages/Events')),
  Event: lazy(() => import('components/pages/Event')),
  MyEvents: lazy(() => import('components/pages/MyEvents')),
  MyEvent: lazy(() => import('components/pages/MyEvent')),
  CreateEventPage: lazy(() => import('components/pages/CreateEventPage')),
  EditEvent: lazy(() => import('components/pages/EditEvent')),
  EventCreateGroups: lazy(() => import('components/pages/EventGroups/EventCreateGroups')),
  EventManageGroups: lazy(() => import('components/pages/EventGroups/EventManageGroups')),
  RegisteredMembers: lazy(() => import('components/pages/RegisteredMembers')),
  StudentsPage: lazy(() => import('components/pages/StudentsPage')),
  Profile: lazy(() => import('components/pages/Profile')),
  ResultPage: lazy(() => import('components/pages/Result/ResultPage')),
  EventResults: lazy(() => import('components/pages/EventResults')),
  ResultEditPage: lazy(() => import('components/pages/Result/ResultEditPage')),
  CertificatesTable: lazy(() => import('components/pages/CertificatesTable')),
  Check: lazy(() => import('components/pages/Check')),
  CreateLocation: lazy(() => import('components/pages/CreateLocation')),
  DirectoriesTable: lazy(() => import('components/pages/DirectoriesTable')),
  CreateGlobalEvent: lazy(() => import('components/pages/CreateGlobalEvent')),
  EditGlobalEvent: lazy(() => import('components/pages/EditGlobalEvent')),
  GlobalEvents: lazy(() => import('components/pages/GlobalEvents')),
  Teams: lazy(() => import('components/pages/Teams')),
  StudentsPhotos: lazy(() => import('components/pages/StudentsPhotos'))
};
