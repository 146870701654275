export enum Routes {
  createEvent = '/create-event',
  registeredMembers = '/events/:id/registered-members',
  confirm = '/confirm',
  landing = '/',
  main = '/main',
  reg = '/reg',
  withoutEmail = '/without-email',
  events = '/events',
  event = '/events/:id',
  myEvents = '/my_events',
  myEvent = '/my_events/:id',
  registration = '/registration',
  registrationOperator = '/registration-of-operator',
  editEvent = '/events/:id/edit-event',
  manageGroups = '/events/:id/manage-groups',
  createGroups = '/events/:id/create-groups',
  students = '/students',
  profile = '/profile/:id',
  results = '/events/:eventId/results',
  result = '/events/:eventId/results/:userId',
  editResult = '/events/:eventId/results/:userId/edit-result',
  agreement = '/agreement',
  check = '/check',
  createLocation = '/create-location',
  directories = '/directories',
  createGlobalEvent = '/create-global-event',
  editGlobalEvent = '/global-events/:id/edit-global-event/',
  globalEvents = '/global-events',
  teams = '/teams',
  studentsPhotos = '/students_photos'
}

export enum RouteRoles {
  default = 'default',
  withoutFullProfile = 'withoutFullProfile',
  student = 'student',
  schoolAndUniversity = 'schoolAndUniversity',
  operator = 'operator',
  testOperator = 'testOperator',
  parent = 'parent'
}
