import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Button, IconButton, ButtonBase } from '@material-ui/core';
import fingram from '../images/fingram.png';
import potencial from '../images/potencial.png';
import pravilnoBystro from '../images/pravilno_bystro.png';
import algorithmics from '../images/algorithmics.png';
import lanit from '../images/lanit.png';
import umschool from '../images/umschool.png';
import { LOGIN_URL } from '../../../../constants/api';
import styles from './styles.scss';


const TopFinanceCmp = (): ReactElement => {
  const events = [
	{
	  name: 'Финансовые компетенции учителей',
	  logo: fingram,
	  extraLink: 'https://mgoprof.ru/?page_id=28079',
	  registrationPeriod: null,
	  dates: [
	    { period: '25.09.2024 - 20.10.2024', type: ''},
	    { period: '21.10.2024 - 20.11.2024', type: ''}
	  ],
	  partners: []
	},
	{
	  name: 'Интеллектуальный мегаполис. Правильно и быстро',
	  logo: pravilnoBystro,
	  extraLink: 'https://im.mcko.ru/key/',
	  registrationPeriod: null,
	  dates: [
	    { period: '07.10.2024 - 23.11.2024', type: '(заочный)'},
	    { period: '14.12.2024', type: '(очный)'}
	  ],
	  partners: [
		{ name: 'ЛАНИТ', link: 'https://im.mcko.ru/key/partners-lanit.php', logo: lanit, needIncreaseSize: false },
		{ name: 'Алгоритмика', link: 'https://im.mcko.ru/key/partners-algoritmika.php', logo: algorithmics, needIncreaseSize: true },
		{ name: 'Умскул', link: 'https://im.mcko.ru/key/partners-umschool.php', logo: umschool, needIncreaseSize: false },
	  ]
	},
	{
	  name: 'Интеллектуальный мегаполис. Потенциал',
	  logo: potencial,
	  extraLink: 'https://im.mcko.ru/potential.php',
	  registrationPeriod: '01.11.2024 - 01.12.2024',
	  dates: [
	    { period: '09.12.2024 - 21.03.2025', type: ''}
	  ],
	  partners: []
	},
  ];
  
  const goToAdditionalInfo = (link: string) => {
    if (link) {
	  window.open(link, '_blank');
	}
  };

  return (
	<section className={styles.section}>
	  <div className={styles.bannerWrapper}>
		<div className={styles.bannerImg}/>
		<div className={styles.eventsWrapper}>
		  { events.map((event, idx) => (
			<div className={styles.eventCard} key={idx}>
			  <img src={event.logo} className={styles.eventLogo}/>
			  <h3 className={styles.eventName}>{event.name}</h3>
			  { event.registrationPeriod && (
				<div className={styles.eventDatesWrapper}>
				  <h4>Регистрация:</h4>
				  <div>{event.registrationPeriod}</div>
				</div>
			  )}
			  <div className={styles.eventDatesWrapper}>
				<h4>Даты проведения:</h4>
				{ event.dates.map((date, dateIdx) => (
					<div className={styles.eventDate} key={idx + '_date_' + dateIdx}>
					  {event.dates.length > 1 ? `${dateIdx + 1} этап `: ''}{date.type ? `${date.type} ` : ''}{date.period}
					</div>
				  )
				)}
			  </div>
			  <div className={styles.eventActions}>
				<Button
				  className={styles.root}
				  variant="outlined"
				  color="default"
				  onClick={() => goToAdditionalInfo(event.extraLink)}
				>
				  Подробнее
				</Button>
				<Button variant="contained" href={LOGIN_URL}>
				  Регистрация
				</Button>
			  </div>
			  { Boolean(event.partners.length) && (
				<div className={styles.partners}>
				  <h3 className={styles.partnersTitle}>ПАРТНЕРЫ 2024 ГОДА</h3>
				  <div className={styles.partnersWrapper}>
					{event.partners.map((partner, idx) => (
					  <ButtonBase className={styles.partnerButton} key={idx} onClick={() => goToAdditionalInfo(partner.link)}>
						<img
						  className={clsx(styles.partnerLogo, {[styles.increaseLogoSize]: partner.needIncreaseSize})}
						  src={partner.logo}
						/>
					  </ButtonBase>
					))}
				  </div>
				</div>
			  )}
			</div>
		  ))}
		</div>
	  </div>
	</section>
  )
};

export const TopFinance = observer(TopFinanceCmp);
